import {
  PatientCoverageEnum,
  PatientResponseWithContact,
} from "@deep-consulting-solutions/be2-constants";
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Collapse,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { ExpandLess } from "@material-ui/icons";

import { formatNameToDisplay } from "helpers";

import { IdentificationDocument } from "redux/serviceCase/types";
import { differenceInYears, format } from "date-fns";
import ViewIdentificationDocuments from "component/ViewIdentificationDocuments";
import { displayPatientCoverageAddedTime } from "../helpers";
import { StatusDisplay } from "./StatusDisplay";
import { CollapsedCard } from "../CollapsedCard";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  wrapper: {
    padding: s(2),
    "&:not(:first-child)": {
      marginTop: s(2),
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    color: "#467ee5",
    fontSize: 20,
    fontWeight: 500,
  },
  expandBtn: {
    borderRadius: s(0.5),
    background: "#f6f6f6",
  },
  expandIcon: {
    fontSize: 25,
  },
  section: {
    marginTop: s(1),
    padding: s(1),
    border: `1px solid ${p.grey[300]}`,
    borderRadius: s(0.5),
  },
  coverage: {
    fontSize: 12,
    fontWeight: 500,
    color: p.grey[700],
  },
  tableContainer: {
    marginTop: s(1),
  },
  tableHeadCell: {
    fontSize: 12,
    fontWeight: 500,
    color: "#192954",
    background: "#ECECEC",
  },
  tableCellText: {
    color: "#192954",
    fontSize: 12,
  },
  tableCellSubtitle: {
    fontSize: 10,
    color: p.grey[400],
  },
  line: {
    display: "flex",
  },
  lineKey: {
    color: p.grey[700],
    fontSize: 12,
    marginRight: s(1),
    maxWidth: "150px",
  },
  lineValue: {
    flex: 1,
    fontSize: 12,
    fontWeight: 600,
    color: p.grey[700],
  },
  link: {
    fontSize: 12,
    color: "#467ee5",
    textDecoration: "underline",
  },
  nok: {
    marginTop: s(1),
  },
  nokContent: {
    marginTop: s(1),
  },
  nokTitle: {
    color: "#263E7F",
    fontSize: 14,
  },
}));

interface Props {
  patient: PatientResponseWithContact;
  identificationDocument?: IdentificationDocument;
  token?: string;
}

export const Patient: React.FC<Props> = ({
  patient,
  identificationDocument,
  token,
}) => {
  const {
    coverageDetails,
    dob,
    preferredDestinationArrivalTime,
    selectedSubsCoverage,
  } = patient;

  const [expand, setExpand] = useState(true);

  const handleExpandToggle = useCallback(() => {
    setExpand((e) => !e);
  }, []);

  const classes = useStyles();

  const renderLine = (key: string, value: any, isSmall?: boolean) => {
    return (
      <Grid item xs={isSmall ? 4 : 6} className={classes.line}>
        <Typography className={classes.lineKey}>{key}:</Typography>
        <Typography className={classes.lineValue}>{value || "--"}</Typography>
      </Grid>
    );
  };

  return (
    <Paper className={classes.wrapper}>
      <div className={classes.header}>
        <Typography className={classes.title}>
          {patient.firstName} {patient.lastName}
        </Typography>
        <IconButton
          size="small"
          className={classes.expandBtn}
          onClick={handleExpandToggle}
        >
          <ExpandLess
            style={{
              transition: "0.2s",
              transform: `rotate(${expand ? "-180" : "-90"}deg)`,
            }}
            color="primary"
            className={classes.expandIcon}
          />
        </IconButton>
      </div>

      <Collapse in={expand}>
        <>
          <div className={classes.section}>
            <Typography className={classes.coverage}>Coverage</Typography>
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeadCell}>
                      Membership
                    </TableCell>
                    <TableCell className={classes.tableHeadCell} align="center">
                      Type
                    </TableCell>
                    <TableCell className={classes.tableHeadCell} align="center">
                      Subscription Status
                    </TableCell>
                    <TableCell className={classes.tableHeadCell} align="center">
                      Plans
                    </TableCell>
                    <TableCell className={classes.tableHeadCell} align="center">
                      Coverage
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.tableCellText}>
                      {coverageDetails?.membershipNumber || "-"}
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes.tableCellText}>
                        {coverageDetails?.isMainbeneficiary
                          ? "Main Beneficiary"
                          : "Dependent"}
                      </Typography>
                      <Typography className={classes.tableCellSubtitle}>
                        Added on{" "}
                        {displayPatientCoverageAddedTime(
                          patient.contact.createdAt
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <StatusDisplay
                        status={coverageDetails?.subscriptionStatus}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellText} align="center">
                      {(selectedSubsCoverage?.subscription?.plans || []).join(
                        ", "
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes.tableCellText}>
                        {coverageDetails?.coverageStatus || "-"}
                      </Typography>
                      {coverageDetails?.coverageStatus ===
                        PatientCoverageEnum.NOT_COVERED && (
                        <Typography className={classes.tableCellSubtitle}>
                          (Benefit not included in plan)
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className={classes.section}>
            <Grid container>
              {renderLine(
                "Age",
                dob ? differenceInYears(new Date(), new Date(dob)) : ""
              )}

              {renderLine("Blood Type", patient.contact?.bloodType)}
              {renderLine("Primary Physician", patient.primaryPhysician)}
              {renderLine("Phone", patient.phone)}
              {renderLine("Preferred Hospital", patient.preferredHospital)}

              <Grid item xs={6} className={classes.line}>
                <ViewIdentificationDocuments
                  patient={patient}
                  identificationDocument={identificationDocument}
                  token={token}
                />
              </Grid>

              {renderLine("Medical Status", patient.medicalStatus)}
            </Grid>
          </div>

          <div className={classes.section}>
            <Grid container>
              {renderLine(
                "Level of Emergency",
                patient.levelOfEmergency || "-"
              )}
              {renderLine(
                "Preferred Date and Time of Arrival",
                preferredDestinationArrivalTime
                  ? format(
                      new Date(
                        preferredDestinationArrivalTime as unknown as string
                      ),
                      "dd MMM yyyy p"
                    )
                  : "-"
              )}
              {renderLine("Medical Needs", patient.medicalNeeds || "-")}
              {renderLine(
                "Patient Can Fly Commercially",
                patient.canFlyCommercially || "-"
              )}
              {renderLine(
                "Requires Medical Escort on the Commercial Flight",
                patient.comercialFlightMedicalEscort || "-"
              )}
            </Grid>
          </div>

          <div className={classes.nok}>
            <CollapsedCard
              title={
                <Typography className={classes.nokTitle}>
                  Next of Kin{" "}
                  <b>
                    {formatNameToDisplay(
                      patient.nextOfKin?.firstName,
                      patient.nextOfKin?.lastName
                    )}
                  </b>
                </Typography>
              }
            >
              <Grid container className={classes.nokContent}>
                {renderLine(
                  "Relation to Patient",
                  patient.nextOfKin?.relationToPatient,
                  true
                )}
                {renderLine("Email", patient.nextOfKin?.email, true)}
                {renderLine("Phone", patient.nextOfKin?.phone, true)}
              </Grid>
            </CollapsedCard>
          </div>
        </>
      </Collapse>
    </Paper>
  );
};
