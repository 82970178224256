import { format } from "date-fns";

export const displayTime = (date: Date) => {
  return format(date, "dd MMM yyyy hh:mm aa");
};

export const displayPatientCoverageAddedTime = (date: Date | string) => {
  return format(
    typeof date === "string" ? new Date(date) : date,
    "dd MMM yyyy"
  );
};
