import { ServiceCaseStatusEnum } from "@deep-consulting-solutions/be2-constants";
import { makeStyles, Typography } from "@material-ui/core";
import React, { useMemo } from "react";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  statusValue: {
    padding: s(0.5, 2),
    borderRadius: 20,
    marginLeft: s(1),
  },
  statusText: {
    fontSize: 10,
    color: p.common.white,
  },
}));

interface Props {
  status: ServiceCaseStatusEnum;
}

export const ServiceCaseStatus: React.FC<Props> = ({ status }) => {
  const background = useMemo(() => {
    if (
      [
        ServiceCaseStatusEnum.PENDING_PLANNING,
        ServiceCaseStatusEnum.SERVICE_SCHEDULED,
        ServiceCaseStatusEnum.RE_OPENED,
      ].includes(status)
    ) {
      return "#dd742d";
    }
    if (
      [
        ServiceCaseStatusEnum.CANCELLED,
        ServiceCaseStatusEnum.REFUSED,
        ServiceCaseStatusEnum,
      ].includes(status)
    ) {
      return "#ba3330";
    }
    return "#08A40F";
  }, [status]);

  const classes = useStyles();
  return (
    <div className={classes.statusValue} style={{ background }}>
      <Typography className={classes.statusText}>{status}</Typography>
    </div>
  );
};
