import { makeStyles, StepIconProps } from "@material-ui/core";
import React from "react";
import { Check } from "@material-ui/icons";

const useStyles = makeStyles(({ palette: p }) => ({
  wrapper: {
    width: 22,
    height: 22,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    background: p.grey[400],
  },
  check: {
    fontSize: 12,
    color: p.common.white,
  },
}));

export const CustomStepIcon: React.FC<StepIconProps> = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Check className={classes.check} />
    </div>
  );
};
