import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import Root from "Root";
import store from "redux/store";

import { ROUTES } from "configs";
import { AccessLinkLogin } from "pages/AccessLinkLogin";
import { AccessLinkDetails } from "pages/AccessLinkDetails";
import { Loader as APILoader } from "@googlemaps/js-api-loader";
import { Loader } from "component/Loader";

export default () => {
  const [isMapsLoaded, setIsMapsLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const apiLoader = new APILoader({
          apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
          libraries: ["places"],
        });
        await apiLoader.load();
        setIsMapsLoaded(true);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    })();
  }, []);

  if (!isMapsLoaded) return <Loader open absolute />;

  return (
    <Root store={store}>
      <Switch>
        <Route
          path={ROUTES.accessLinkDetails.path}
          component={AccessLinkDetails}
        />
        <Route path={ROUTES.accessLinkLogin.path} component={AccessLinkLogin} />
      </Switch>
    </Root>
  );
};
