import React from "react";
import {
  Divider,
  makeStyles,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";

import { ServiceCaseResponse } from "@deep-consulting-solutions/be2-constants";
import { ReactComponent as LocationLogo } from "./location.svg";
import { CustomStepIcon } from "./CustomStepIcon";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  wrapper: {
    padding: s(1),
    border: `1px solid ${p.grey[300]}`,
    borderRadius: s(0.5),
  },
  locationLabel: {
    fontSize: 12,
    fontWeight: 500,
    color: p.grey[700],
  },
  locationContent: {
    display: "flex",
    alignItems: "center",
  },
  locationName: {
    color: p.primary.main,
    fontSize: 12,
    marginLeft: s(1),
  },
  stepper: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  bottom: {
    paddingLeft: "28px",
  },
  message: {
    marginTop: s(2),
    color: p.grey[700],
    fontSize: 12,
  },
}));

interface Props {
  serviceCase: ServiceCaseResponse;
}

export const Itinerary: React.FC<Props> = ({ serviceCase }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div>
        <Stepper
          orientation="vertical"
          activeStep={2}
          className={classes.stepper}
        >
          <Step active>
            <StepLabel StepIconComponent={CustomStepIcon}>
              <Typography className={classes.locationLabel}>
                Initial Location
              </Typography>
            </StepLabel>
            <StepContent>
              <div className={classes.locationContent}>
                <LocationLogo />
                <Typography className={classes.locationName}>
                  {serviceCase.initialLocation}
                  {serviceCase.initialLocationAddress
                    ? `, ${serviceCase.initialLocationAddress}`
                    : ""}
                </Typography>
              </div>
            </StepContent>
          </Step>
          <Step active>
            <StepLabel StepIconComponent={CustomStepIcon}>
              <Typography className={classes.locationLabel}>
                Destination Location
              </Typography>
            </StepLabel>
            <StepContent>
              <div className={classes.locationContent}>
                <LocationLogo />
                <Typography className={classes.locationName}>
                  {serviceCase.destinationLocation}
                  {serviceCase.destinationLocationAddress
                    ? `, ${serviceCase.destinationLocationAddress}`
                    : ""}
                </Typography>
              </div>
            </StepContent>
          </Step>
        </Stepper>
      </div>

      <div className={classes.bottom}>
        <Divider />
        <Typography className={classes.message}>
          Itinerary Details: <b>{serviceCase.itineraryDetails || "-"}</b>
        </Typography>
        <Typography className={classes.message}>
          Assigned Staff: <b>{serviceCase.assignedStaff || "-"}</b>
        </Typography>
      </div>
    </div>
  );
};
