import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppBar, Divider, Paper, Toolbar, Typography } from "@material-ui/core";
import { parse } from "query-string";

import { AccessLinkDetailsQueryParams, AccessLinkHistoryState } from "configs";

import { serviceCaseRequests } from "redux/serviceCase";
import { Loader } from "component/Loader";

import { IdentificationDocument } from "redux/serviceCase/types";
import { Map } from "component/Map";
import { ReactComponent as BELogo } from "../AccessLinkLogin/be_logo.svg";
import { displayTime } from "./helpers";
import { useStyles } from "./styles";
import { ServiceInformation } from "./ServiceInformation";
import { Itinerary } from "./Itinerary";
import { Patient } from "./Patient";

export const AccessLinkDetails = () => {
  const { search, state } = useLocation<AccessLinkHistoryState | undefined>();
  const { token } = useMemo(() => {
    return parse(search) as AccessLinkDetailsQueryParams;
  }, [search]);

  const [serviceCase, setServiceCase] = useState(state?.serviceCase);
  const [loading, setLoading] = useState(true);
  const [documentToken, setDocumentToken] = useState(
    state?.documentToken || ""
  );
  const [identificationDocuments, setIdentificationDocuments] = useState<
    IdentificationDocument[]
  >(state?.identificationDocuments || []);

  useEffect(() => {
    (async () => {
      if (state?.serviceCase) {
        setLoading(false);
        return;
      }

      if (!token) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);

        const res = await serviceCaseRequests.fetchAccessLinkDetailsWithToken(
          token
        );
        setServiceCase(res.serviceCase);
        setIdentificationDocuments(res.identificationDocuments);
        setDocumentToken(res.documentToken);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    })();
  }, [state?.serviceCase, token]);

  const getPatientIdentificationDocument = useCallback(
    (contactID: string) =>
      identificationDocuments.find(
        (identificationDocument) =>
          identificationDocument.contactId === contactID
      ),
    [identificationDocuments]
  );

  const classes = useStyles();

  useEffect(() => {
    document.body.className = classes.body;
  }, [classes.body]);

  if (loading) {
    return (
      <div>
        <Loader open />
      </div>
    );
  }

  if (!serviceCase) {
    return (
      <div className={classes.noServiceCase}>
        <Typography className={classes.noServiceCaseText}>
          Something went wrong
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <AppBar position="fixed" className={classes.head}>
        <Toolbar>
          <BELogo className={classes.logo} />
          <Typography className={classes.headTitle}>
            BahamasEvac Service Cases{" "}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.inner}>
        <div className={classes.innerSection}>
          <Paper className={classes.info}>
            <div className={classes.infoHead}>
              <Typography className={classes.infoHeadTitle}>
                {serviceCase.caseNumber} - {serviceCase.requestType}
              </Typography>
              <Typography className={classes.infoHeadDispatcher}>
                Dispatcher:{" "}
                <b>
                  {serviceCase.dispatcher.firstName}{" "}
                  {serviceCase.dispatcher.lastName}
                </b>
              </Typography>
              <Typography className={classes.infoHeadTime}>
                {displayTime(new Date(serviceCase.updatedAt))}
              </Typography>
            </div>
            <div className={classes.divider}>
              <Divider />
            </div>
            <Typography className={classes.metaSectionTitle}>
              Service Details
            </Typography>
            <Paper className={classes.sectionPaper}>
              <div className={classes.section}>
                <Typography className={classes.sectionTitle}>
                  Service
                </Typography>
                <ServiceInformation serviceCase={serviceCase} />
              </div>
              <div className={classes.section}>
                <Typography className={classes.sectionTitle}>
                  Itinerary
                </Typography>
                <Itinerary serviceCase={serviceCase} />
              </div>
            </Paper>
            <div className={classes.secondDivider}>
              <Divider />
            </div>
            <Typography className={classes.metaSectionTitle}>
              Patients
            </Typography>

            <div>
              {(serviceCase.patients || []).map((patient) => {
                return (
                  <Patient
                    key={patient.patientID}
                    patient={patient as any}
                    identificationDocument={getPatientIdentificationDocument(
                      patient.contactID
                    )}
                    token={documentToken}
                  />
                );
              })}
            </div>
          </Paper>
        </div>

        <div className={classes.innerSection}>
          <Map serviceCase={serviceCase} />
        </div>
      </div>
    </div>
  );
};
