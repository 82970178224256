import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  body: {
    background: p.grey[200],
  },
  wrapper: {
    width: "100%",
  },
  head: {
    background: p.common.white,
  },
  logo: {
    width: 80,
    marginRight: "auto",
  },
  headTitle: {
    fontSize: 18,
    color: p.primary.dark,
  },
  inner: {
    marginTop: 70,
    padding: s(1),
    display: "flex",
  },
  innerSection: {
    width: `calc(50% - ${s(1)}px)`,
    "&:first-child": {
      marginRight: s(2),
    },
  },
  info: {
    padding: s(1),
    borderRadius: s(1),
  },
  infoHead: {
    display: "flex",
    alignItems: "center",
  },
  infoHeadTitle: {
    fontSize: 18,
    fontWeight: 600,
    color: p.primary.main,
  },
  infoHeadDispatcher: {
    flex: 1,
    textAlign: "center",
    color: p.primary.dark,
    fontSize: 12,
  },
  infoHeadTime: {
    fontSize: 12,
    color: p.grey[700],
  },
  divider: {
    marginTop: s(2),
    marginBottom: s(2),
  },
  metaSectionTitle: {
    fontSize: 24,
    fontWeight: 500,
    color: "#263E7F",
    marginBottom: s(2),
  },
  sectionPaper: {
    padding: s(2),
  },
  section: {
    "&:not(:last-child)": {
      marginBottom: s(2),
    },
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 500,
    color: "#263E7F",
    marginBottom: s(2),
  },
  secondDivider: {
    marginTop: s(4),
    marginBottom: s(2),
  },
  noServiceCase: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noServiceCaseText: {
    fontSize: 20,
    color: p.error.main,
  },
}));
