import { makeStyles } from "@material-ui/core";
import React from "react";
import { Loader } from "component/Loader";

const useStyles = makeStyles(({ spacing: s }) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: s(2),
    position: "relative",
    width: "100%",
    height: "100%",
    background: "white",
    borderRadius: 8,
    zIndex: 1,
  },
}));

export const MapLoadingComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Loader absolute open />
    </div>
  );
};
