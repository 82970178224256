import axios, { AxiosError } from "axios";

import { notifications } from "services";

export const createClient = (options?: {
  baseUrl?: string;
  disableActionsOnError?: boolean;
}) => {
  const client = axios.create({
    baseURL: options?.baseUrl ?? process.env.REACT_APP_BASE_URL,
  });
  const silentAxiosClient = axios.create({
    baseURL: options?.baseUrl ?? process.env.REACT_APP_BASE_URL,
  });

  const setAuthorizationHeader = (token: string) => {
    client.defaults.headers.Authorization = `Bearer ${token}`;
    silentAxiosClient.defaults.headers.Authorization = `Bearer ${token}`;
  };

  const getAuthorizationToken = (): string | undefined => {
    return (client.defaults.headers.Authorization as string | null)?.replace(
      "Bearer ",
      ""
    );
  };

  const removeAuthorizationHeader = () => {
    delete client.defaults.headers.Authorization;
    delete silentAxiosClient.defaults.headers.Authorization;
  };

  // Add a response interceptor
  client.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async (error: AxiosError) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      let message =
        error?.response?.data?.message || error?.message || "Unknown error";

      if (typeof message !== "string") {
        try {
          message = JSON.stringify(message);
        } catch {
          message = "Unknown error";
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      notifications.notifyError(message, {
        persist: [408, 500, 501, 502, 503, 504].includes(
          error.response?.status as number
        ),
      });

      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  silentAxiosClient.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async (error: AxiosError) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      return Promise.reject(error);
    }
  );

  return {
    client,
    silentClient: silentAxiosClient,
    setAuthorizationHeader,
    removeAuthorizationHeader,
    getAuthorizationToken,
  };
};

export const {
  client: apiClient,
  silentClient,
  removeAuthorizationHeader,
  setAuthorizationHeader,
  getAuthorizationToken,
} = createClient();

export const {
  client: crmClient,
  silentClient: crmSilentClient,
  removeAuthorizationHeader: removeCRMAuthHeader,
  setAuthorizationHeader: setCRMAuthHeader,
} = createClient({ disableActionsOnError: true });

export const getClient = (isCRM?: boolean) => {
  return isCRM ? crmClient : apiClient;
};
