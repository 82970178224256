import { SubscriptionStatusEnum } from "@deep-consulting-solutions/be2-constants";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  status?: SubscriptionStatusEnum | null;
}

const useStyles = makeStyles<Theme, Props>(({ palette: p, spacing: s }) => ({
  outer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    background: "#08A40F",
    padding: s(0.5, 2),
    borderRadius: 50,
    textAlign: "center",
    // width: 100,
  },
  text: {
    fontSize: 10,
    fontWeight: 500,
    color: p.common.white,
  },
}));

export const StatusDisplay: React.FC<Props> = ({ status }) => {
  const classes = useStyles({ status });

  if (!status) return <>-</>;

  return (
    <div className={classes.outer}>
      <div className={classes.wrapper}>
        <Typography className={classes.text}>{status}</Typography>
      </div>
    </div>
  );
};
