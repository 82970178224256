import React, { useCallback, useState } from "react";
import {
  Button,
  FormHelperText,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form, Field, FormikConfig } from "formik";
import { TextField } from "formik-material-ui";
import { useHistory } from "react-router-dom";

import { serviceCaseRequests } from "redux/serviceCase";
import { Loader } from "component/Loader";
import { ROUTES } from "configs";

import { ReactComponent as BELogo } from "./be_logo.svg";

interface FormValues {
  caseNumber: string;
  pin: string;
}

const validationSchema = Yup.object<FormValues>({
  caseNumber: Yup.string().required("This is required"),
  pin: Yup.string().required("This is required"),
});

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inner: {
    width: "370px",
    padding: s(3, 2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  name: {
    color: p.primary.dark,
    fontSize: 16,
    marginTop: s(2),
    marginBottom: s(2),
  },
  inputWrapper: {
    marginTop: s(2),
  },
  input: {
    "&::placeholder": {
      fontSize: 14,
    },
    fontSize: 14,
  },
  actions: {
    marginTop: s(2),
    textAlign: "right",
  },
  errorMessage: {
    marginTop: s(1),
    fontSize: 12,
  },
}));

export const AccessLinkLogin = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleFormSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values) => {
      try {
        setShowErrorMessage(false);
        setLoading(true);

        const res = await serviceCaseRequests.loginAccessLinkWithPin(
          values.caseNumber,
          values.pin
        );

        history.push({
          pathname: ROUTES.accessLinkDetails.path.replace(
            ":caseNumber",
            values.caseNumber
          ),
          state: res,
        });
      } catch {
        setLoading(false);
        setShowErrorMessage(true);
      }
    },
    [history]
  );

  const classes = useStyles();
  return (
    <Formik<FormValues>
      initialValues={{
        caseNumber: "",
        pin: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {() => {
        return (
          <div className={classes.wrapper}>
            <Loader open={loading} />
            <Paper className={classes.inner}>
              <BELogo />
              <Typography className={classes.name}>
                BahamsEvac Service Cases
              </Typography>
              <Form>
                <Field
                  name="caseNumber"
                  component={TextField}
                  label="Case Number"
                  required
                  className={classes.inputWrapper}
                  InputProps={{
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    className: classes.input,
                  }}
                />
                <Field
                  name="pin"
                  component={TextField}
                  label="PIN"
                  required
                  className={classes.inputWrapper}
                  InputProps={{
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    className: classes.input,
                  }}
                  type="password"
                />
                {showErrorMessage && (
                  <FormHelperText error className={classes.errorMessage}>
                    The Case Number and PIN combination is incorrect
                  </FormHelperText>
                )}
                <div className={classes.actions}>
                  <Button color="primary" type="submit">
                    Open
                  </Button>
                </div>
              </Form>
            </Paper>
          </div>
        );
      }}
    </Formik>
  );
};
