import { ServiceCaseResponse } from "@deep-consulting-solutions/be2-constants";
import { IdentificationDocument } from "redux/serviceCase/types";

export const ROUTES = {
  accessLinkDetails: {
    path: "/:caseNumber",
  },
  accessLinkLogin: {
    path: "/",
  },
};

export interface AccessLinkDetailsRouteParams {
  caseNumber: string;
}

export interface AccessLinkDetailsQueryParams {
  token?: string;
}

export interface AccessLinkHistoryState {
  documentToken?: string;
  serviceCase?: ServiceCaseResponse;
  identificationDocuments?: IdentificationDocument[];
}
