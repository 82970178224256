import { GenderEnum } from "@deep-consulting-solutions/be2-constants";

export const capitalizeFirstLetter = (str: string) => {
  if (!str.length) return str;
  return str[0].toUpperCase() + str.slice(1);
};

export const hideEmailAddress = (email?: string): string => {
  if (!email) return "";
  const splits = email.split("@");
  const name = splits[0] || "";
  const fullDomain = splits[1] || "";
  const domainSplits = fullDomain.split(".");
  const domain = domainSplits[0] || "";
  const domainHost = domainSplits[1] || "";
  const firstChar = name[0] || "";
  const lastChar = name.length > 1 ? name[name.length - 1] : "";
  const asterisks = "*".repeat(8);
  const domainFirstCharacter = domain[0];
  const domainAsterisks = "*".repeat(domain.length - 1);
  return `${firstChar}${asterisks}${lastChar}@${domainFirstCharacter}${domainAsterisks}.${domainHost}`;
};

export const removeSecondOccur = (str: string, char: string) => {
  return str.split(char).slice(0, 2).join(char);
};

export const composeUserName = (
  data?: {
    firstName?: string | null;
    lastName?: string | null;
  } | null
) => {
  if (!data) return "";
  const { firstName, lastName } = data;
  let name = firstName || "";
  if (firstName && lastName) name += " ";
  name += lastName || "";
  return name;
};

export const formatNameToDisplay = (firstName?: string, lastName?: string) => {
  return `${firstName || ""} ${lastName || ""}`.trim();
};

export const displayGender = (gender?: string | null): string => {
  if (!gender) return "";
  if (gender === GenderEnum.MALE) return "Male";
  return "Female";
};

export const truncateFileName = (fileName: string) => {
  const parts = fileName.split(".");
  const name = parts.slice(0, parts.length - 1).join(".");
  const ending = parts[parts.length - 1];
  if (name.length < 11) return fileName;
  return `${name.slice(0, 4)}...${name.slice(name.length - 5)}.${ending}`;
};

export const displayPrice = (price: number) => {
  return price.toFixed(2);
};

export const convertNumberToOrdinal = (num: number) => {
  const tens = num % 10;
  const hundreds = num % 100;
  if (tens === 1 && hundreds !== 11) {
    return `${num}st`;
  }
  if (tens === 2 && hundreds !== 12) {
    return `${num}nd`;
  }
  if (tens === 3 && hundreds !== 13) {
    return `${num}rd`;
  }
  return `${num}th`;
};

export const displayPercentage = (num: number) => {
  return `${num * 100}%`;
};
