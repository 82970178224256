import { GetAccessLinkS3DocumentDownloadSignedUrl } from "@deep-consulting-solutions/be2-constants";
import { apiClient, setAuthorizationHeader } from "apis";

export const fetchS3DownloadURL = async (id: string, token: string) => {
  setAuthorizationHeader(token);

  const res = await apiClient.get<
    typeof GetAccessLinkS3DocumentDownloadSignedUrl.Res
  >(GetAccessLinkS3DocumentDownloadSignedUrl.ROUTE.replace(":documentId", id));

  return res.data.data;
};
