import cx from "clsx";
import { Page, Document } from "react-pdf";
import { Close } from "@material-ui/icons";
import { SelectProps } from "formik-material-ui";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  Box,
  Dialog,
  Select,
  MenuItem,
  Typography,
  makeStyles,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogContent,
} from "@material-ui/core";
import {
  DocumentTypeEnum,
  PatientResponseWithContact,
} from "@deep-consulting-solutions/be2-constants";

import { formatNameToDisplay } from "helpers";
import { Loader } from "component/Loader";
import {
  IdentificationDocument,
  IdentityDocument,
} from "redux/serviceCase/types";
import { documentRequests } from "./documents";

type DocumentsType = IdentityDocument & {
  src: string | File;
};

interface Props {
  textClassName?: string;
  patient: PatientResponseWithContact;
  identificationDocument?: IdentificationDocument;
  token?: string;
}

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  dialogTitle: {
    display: "flex",
    backgroundColor: "#F6F6F6",
    justifyContent: "space-between",
  },
  titleText: {
    textTransform: "capitalize",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "24px",
    color: p.primary.main,
  },
  text: {
    cursor: "pointer",
    textTransform: "none",
  },
  dialogContent: {
    padding: s(2),
  },
  document: {
    width: "100%",
    height: "100%",
    maxHeight: "527px",
    objectFit: "contain",
    objectPosition: "center top",

    "& .react-pdf__Page__canvas": {
      width: "100% !important",
      height: "100% !important",
      maxHeight: "527px",
      objectFit: "contain",
    },
  },
  link: {
    fontSize: 12,
    color: "#467ee5",
    textDecoration: "underline",
  },
  none: {
    display: "none",
  },
  block: {
    display: "block",
  },
}));

const ViewIdentificationDocuments = ({
  patient,
  textClassName,
  identificationDocument,
  token,
}: Props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fullName = formatNameToDisplay(patient.firstName, patient.lastName);
  const [documents, setDocuments] = useState<DocumentsType[]>([]);
  const [selected, setSelected] = useState<DocumentTypeEnum | string>("");

  const handleSelectChange: SelectProps["onChange"] = useCallback((e) => {
    setSelected((e.target.value as DocumentTypeEnum) || "");
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!identificationDocument?.documents || !token) {
          return;
        }
        setLoading(true);

        const docs = identificationDocument.documents;

        const completeDocs = await Promise.all(
          docs.map(async (document) => {
            const url = (
              await documentRequests.fetchS3DownloadURL(
                document.documentId,
                token
              )
            ).url;

            const isDocumentAnImage =
              document.mimeType?.includes("image") || false;

            if (isDocumentAnImage) {
              return {
                ...document,
                src: url,
              };
            }

            const response = await fetch(url);
            const blob = await response.blob();
            const file = new File(
              [blob],
              document.fileName || document.documentId
            );

            return {
              ...document,
              src: file,
            };
          })
        );

        setDocuments(completeDocs);

        if (completeDocs[0]) {
          setSelected(completeDocs[0].type);
        }
      } catch {
        //
      } finally {
        setLoading(false);
      }
    })();
  }, [patient.contactID, identificationDocument?.documents, token]);

  const documentTypes = useMemo(() => {
    return Array.from(new Set(documents.map((document) => document.type)));
  }, [documents]);

  return (
    <>
      <Typography
        role="button"
        variant="button"
        onClick={() => setIsOpen(true)}
        className={cx(classes.text, classes.link, textClassName)}
      >
        View Patient Identification
      </Typography>

      <Dialog open={isOpen} fullWidth>
        <Loader open={loading} absolute />

        <DialogTitle className={classes.dialogTitle} disableTypography>
          <Typography className={classes.titleText}>
            Identification Documents - {fullName}
          </Typography>

          <Close
            style={{ cursor: "pointer" }}
            onClick={() => setIsOpen(false)}
          />
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {documents.length > 1 ? (
            <FormControl
              fullWidth
              style={{ marginTop: "8px", marginBottom: "16px" }}
            >
              <InputLabel id="document-type-select-label" variant="outlined">
                Document Type
              </InputLabel>

              <Select
                variant="outlined"
                label="Document Type"
                id="document-type-select"
                value={selected || ""}
                onChange={handleSelectChange}
                labelId="document-type-select-label"
              >
                {documentTypes.map((documentType) => (
                  <MenuItem key={documentType} value={documentType}>
                    {documentType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}

          <Box
            sx={{
              p: 1,
              boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.12)",
              overflow: "auto",
            }}
          >
            {selected ? (
              <Box>
                <Box style={{ padding: "8px 16px" }}>
                  <Typography>{selected}</Typography>
                </Box>

                <Box width="100%" height={527}>
                  {documents
                    .filter((document) => typeof document.src === "string")
                    .map((document) => (
                      <img
                        src={document.src as string}
                        className={classes.document}
                        style={{
                          display:
                            document.type === selected ? "block" : "none",
                        }}
                        key={document.documentId}
                        alt={`${document.type} for ${fullName}`}
                      />
                    ))}

                  {documents
                    .filter((document) => typeof document.src !== "string")
                    .map((document) => (
                      <Document
                        file={document.src}
                        className={cx(
                          classes.document,
                          document.type === selected
                            ? classes.block
                            : classes.none
                        )}
                        loading={<Loader open absolute />}
                        key={document.documentId}
                      >
                        <Page pageNumber={1} renderAnnotationLayer={false} />
                      </Document>
                    ))}
                </Box>
              </Box>
            ) : (
              !loading && (
                <Box style={{ padding: "8px 16px" }}>
                  <Typography variant="body2">
                    No documents to display.
                  </Typography>
                </Box>
              )
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewIdentificationDocuments;
