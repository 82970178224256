import React, { useEffect, useRef, useState } from "react";
import { MapProvider, useMap } from "context";
import { makeStyles } from "@material-ui/core";
import Theme from "theme";
import { ServiceCaseResponse } from "@deep-consulting-solutions/be2-constants";
import { MapErrorComponent } from "./MapErrorComponent";
import { MapLoadingComponent } from "./MapLoadingComponent";

interface StyleProps {
  listener: string;
}

interface Props {
  serviceCase: ServiceCaseResponse;
}

const useStyles = makeStyles<typeof Theme, StyleProps>(({ palette: p }) => ({
  wrapper: {
    height: "100%",
    width: "100%",
    background: "white",
    borderRadius: 8,
    position: "relative",
    border: ({ listener }) =>
      listener ? `3px solid ${p.primary.main}` : "none",
  },
}));

export const RenderMap = ({ serviceCase }: Props) => {
  const [loading] = useState(false);
  const [error] = useState<string | undefined>();

  const ref = useRef<HTMLDivElement | null>(null);

  const {
    createMap,
    listener,
    createInitialLocationMarker,
    createDestinationMarker,
    setMapZoom,
  } = useMap();

  const classes = useStyles({ listener });

  useEffect(() => {
    if (ref.current) {
      createMap(ref.current, {
        zoom: 9,
        mapTypeControl: false,
        streetViewControl: false,
        // center is set to the Bahamas.
        center: { lat: 24.6886111, lng: -77.9758333 },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (serviceCase?.initialLocation && serviceCase?.destinationLocation) {
      const [iLat, iLng] = serviceCase.initialLocation.split(",").map(Number);
      const [dLat, dLng] = serviceCase.destinationLocation
        .split(",")
        .map(Number);
      if (iLng || dLng) {
        createInitialLocationMarker({
          position: { lat: iLat, lng: iLng },
        });
        createDestinationMarker({
          position: { lat: dLat, lng: dLng },
        });
      }
    }
  }, [
    createDestinationMarker,
    createInitialLocationMarker,
    createMap,
    serviceCase?.initialLocation,
    serviceCase?.destinationLocation,
    setMapZoom,
  ]);

  return (
    <div className={classes.wrapper} ref={ref} id="call-form-map">
      {error ? <MapErrorComponent message={error} /> : null}
      {loading ? <MapLoadingComponent /> : null}
    </div>
  );
};

export const Map = ({ serviceCase }: Props) => {
  return (
    <MapProvider>
      <RenderMap serviceCase={serviceCase} />
    </MapProvider>
  );
};
