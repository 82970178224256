/* eslint-disable @typescript-eslint/no-unused-vars */
import { PostVerifyServiceCaseAccessLink } from "@deep-consulting-solutions/be2-constants";
import { stringify } from "query-string";

import { apiClient } from "apis";

export const loginAccessLinkWithPin = async (
  caseNumber: string,
  pin: string
) => {
  const body = {
    isPinBased: true,
    caseNumber,
    pin,
  };
  const res = await apiClient.post<
    typeof PostVerifyServiceCaseAccessLink["Res"]
  >(PostVerifyServiceCaseAccessLink.ROUTE, body);
  return res.data.data;
};

export const fetchAccessLinkDetailsWithToken = async (token: string) => {
  const body: typeof PostVerifyServiceCaseAccessLink["Body"] = {
    isPinBased: false,
    token,
  };
  const res = await apiClient.post<
    typeof PostVerifyServiceCaseAccessLink["Res"]
  >(PostVerifyServiceCaseAccessLink.ROUTE, body);
  return res.data.data;
};
