import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { ServiceCaseResponse } from "@deep-consulting-solutions/be2-constants";

import { ServiceCaseStatus } from "component/ServiceCaseStatus";

import { CollapsedCard } from "../CollapsedCard";

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  title: {
    color: "#263E7F",
    fontSize: 14,
    fontWeight: 500,
  },
  body: {
    marginTop: s(1.5),
  },
  bodyLine: {
    display: "flex",
    alignItems: "center",
    "&:not(:last-child)": {
      marginBottom: s(1.5),
    },
  },
  service: {
    fontSize: 12,
    color: p.grey[700],
    width: "50%",
  },
  status: {
    width: "50%",
    display: "flex",
    alignItems: "center",
  },
  statusKey: {
    fontSize: 12,
    color: p.grey[700],
    marginRight: s(1),
  },
}));

interface Props {
  serviceCase: ServiceCaseResponse;
}

export const ServiceInformation: React.FC<Props> = ({ serviceCase }) => {
  const classes = useStyles();
  return (
    <CollapsedCard
      title={
        <Typography className={classes.title}>Service Information</Typography>
      }
    >
      <div className={classes.body}>
        <div className={classes.bodyLine}>
          <Typography className={classes.service}>
            Service to be Rendered: <b>{serviceCase.service}</b>
          </Typography>

          <div className={classes.status}>
            <Typography className={classes.statusKey}>Status:</Typography>
            <ServiceCaseStatus status={serviceCase.caseStatus} />
          </div>
        </div>

        <div className={classes.bodyLine}>
          <Typography className={classes.service}>
            Medical Director:{" "}
            <b>{serviceCase.medicalDirector?.zohoUser.name || ""}</b>
          </Typography>
        </div>
      </div>
    </CollapsedCard>
  );
};
